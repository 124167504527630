import { App } from "infrontjs";

class MyApp extends App
{
    constructor()
    {
        super(
            {
                "title" : null,
                "container" : document.getElementById( 'app' )
            }
        );
    }
}

const codeSpeed = 50;
const commentSpeed = 5;

/*
const myApp = new IF.App();
myApp.run()
*/
new TypeIt(
    "#code-editor",
    {
        html : true,
        cursorChar: '<span style="background-color: #708198">&nbsp;</span>'
    }
)
    .options( { speed: commentSpeed } )
    .type( "<br />" )
    .type( "<i>// Welcome to the official InfrontJS webpage.</i><br />"  )
    .type( "<i>// <br /></i>" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .pause( 250 )
    .type( "<i>// Let's create a simple InfrontJS application.</i><br />" )
    .type( "<br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .pause( 2000 )
    .empty()
    .type( "<i>// First, we create an InfrontJS app instance</i><br />" )
    .pause( 1500 )
    .options( { speed: codeSpeed } )
    .type( "<span style='font-weight: bold;color: #290064;'>const</span> <span style='color: #dc2fff'>myApp</span> = <span style='font-weight: bold;color: #290064;'>new</span> IF.App();<br />" )
    .type( "<br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .pause( 150 )
    .options( { speed: commentSpeed } )
    .type( "<i>// Now we just call run() on the instance</i><br />" )
    .pause( 1500 )
    .options( { speed: codeSpeed } )
    .type( "<span style='color: #dc2fff'>myApp</span>.run();" )
    .pause( 500 )
    .type( "<br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .exec( () => {const app = new MyApp();app.run();})
    .pause( 3000 )
    .options( { speed: commentSpeed } )
    .empty()
    .type( "<br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .type( "<i>// You see the most minimal InfrontJS app showing the default scene (aka shameless plug).</i>😉<br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    //.type( "<i>// Please have a look at the user guides, sources and docs to learn more about InfrontJS.</i><br />" )
    .type( "<i>// Stay tuned for the final release of InfrontJS version 1.0.</i><br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .type( "<i>//</i><br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .type( "<i>// Feedback, questions and thoughts are highly appreciated.</i><br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .type( "<i>// Enjoy!</i> ✌️ <br />" )
    .exec( () => { document.querySelector( '.ui-card' ).scrollTop = '200' } )
    .go();
